<template>
  <v-container v-if="checkPrivilege('email:read')" fluid pa-0>
    <dialog-email-detail ref="dialogEmailDetail" />

    <email-table-toolbar
      @change-date-from="changeDateFrom($event)"
      @change-date-to="changeDateTo($event)"
      @text-search-filter="textSearchFilter($event)"
    />
    <email-table
      height="calc(100vh - 180px)"
      :emails="emails"
      :filter-params="emailTableFilterParams"
      @on-click-email="onClickEmail($event)"
    />
  </v-container>
</template>

<script>
import Snackbar from 'Helpers/snackbar/index'
import { mapGetters } from 'vuex'
import DialogEmailDetail from './DialogEmailDetail/DialogEmailDetail'
import EmailTable from './EmailTable/EmailTable'
import EmailTableToolbar from './EmailTableToolbar/EmailTableToolbar'

export default {
  components: {
    DialogEmailDetail,
    EmailTable,
    EmailTableToolbar,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['emails', 'emailTableFilterParams']),
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      try {
        this.$store.dispatch('initEmailTable')
        this.$store.dispatch('getEmails').catch((err) => {
          Snackbar.error(err.message)
        })
      } catch (e) {
        console.log(e)
      }
    },
    onClickEmail(emailId) {
      try {
        this.$store
          .dispatch('getEmail', { emailId: emailId })
          .then((res) => {
            this.$refs.dialogEmailDetail.open(res.email_data)
          })
          .catch((err) => {
            Snackbar.error(err.message)
          })
      } catch (e) {
        console.log(e)
      }
    },
    changeDateFrom(dateFrom) {
      this.$store.dispatch('changeDateFrom', dateFrom)
    },
    changeDateTo(dateTo) {
      this.$store.dispatch('changeDateTo', dateTo)
    },
    textSearchFilter(text) {
      this.$store.dispatch('setTextSearchFilter', text)
    },
  },
}
</script>
