function checkSearchText(item, text) {
  if (!text) {
    return true
  }
  if (text.includes('成功')) {
    return item.is_success
  } else if (text.includes('失敗')) {
    return !item.is_success
  }

  return (
    item.subject.includes(text) ||
    item.to.includes(text) ||
    item.from_email.includes(text) ||
    item.date_created.includes(text)
  )
}

export function filterEmailTable(table, { text }) {
  let resultTable = JSON.parse(JSON.stringify(table))
  resultTable = resultTable.filter((item) => {
    return checkSearchText(item, text)
  })

  return resultTable
}
