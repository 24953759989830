<template>
  <v-toolbar flat>
    <template class="mr-5">
      <date-pick-input v-model="form.dateFrom" @change="onChangeDateFrom()" />
      <span class="mx-2">〜</span>
      <date-pick-input v-model="form.dateTo" @change="onChangeDateTo()" />
    </template>

    <v-text-field
      v-model="form.text"
      append-icon="search"
      placeholder="簡単フィルタ"
      hide-details
      clearable
      class="more-dense ml-5"
      style="max-width: 25%"
      dense
      filled
      @input="$emit('text-search-filter', { text: form.text })"
    />
  </v-toolbar>
</template>

<script>
import { mapGetters } from 'vuex'
import DatePickInput from 'Components/Input/DatePickInput'

export default {
  components: {
    DatePickInput,
  },
  props: {},
  data() {
    return {
      form: {
        dateFrom: null,
        dateTo: null,
        text: '',
      },
    }
  },
  computed: {
    ...mapGetters({}),
  },
  watch: {},
  mounted() {
    this.setUpForm()
  },
  methods: {
    setUpForm() {
      const emailTableParams = JSON.parse(
        localStorage.getItem('emailTableParams')
      )
      if (emailTableParams && emailTableParams.dateFrom) {
        this.form.dateFrom = emailTableParams.dateFrom.slice(0, 10)
      }
      if (emailTableParams && emailTableParams.dateTo) {
        this.form.dateTo = emailTableParams.dateTo.slice(0, 10)
      }
      const emailTableFilterParams = JSON.parse(
        localStorage.getItem('emailTableFilterParams')
      )
      if (
        emailTableFilterParams &&
        emailTableFilterParams.text != undefined &&
        emailTableFilterParams.text != null
      ) {
        this.form.text = emailTableFilterParams.text
      }
    },
    onChangeDateFrom() {
      this.$emit('change-date-from', { dateFrom: this.form.dateFrom })
    },
    onChangeDateTo() {
      this.$emit('change-date-to', { dateTo: this.form.dateTo })
    },
  },
}
</script>
