<template>
  <v-data-table
    :headers="headers"
    :items="filteredEmails"
    fixed-header
    :footer-props="footerProps"
    class="px-4"
    :height="height"
    style="width: 100%"
  >
    <template #item="{ item, isSelected }">
      <tr
        :class="isSelected ? 'v-data-table__selected' : ''"
        style="cursor: pointer"
        @click="$emit('on-click-email', item.id)"
      >
        <td>
          {{ item.subject }}
        </td>
        <td>
          {{ item.from_email }}
        </td>
        <td>
          {{ item.to }}
        </td>
        <td>
          {{ item.date_created }}
        </td>
        <td>
          <v-chip
            v-if="item.is_success"
            label
            x-small
            class="px-3"
            color="success"
            outlined
          >
            成功
          </v-chip>
          <v-chip v-else label x-small class="px-3" color="error" outlined>
            失敗
          </v-chip>
        </td>
      </tr>
    </template>

    <template #no-results>
      <v-alert :value="true" color="yellow" icon="warning" class="mt-3">
        {{ $t('message.noResult') }}
      </v-alert>
    </template>
  </v-data-table>
</template>

<script>
import { filterEmailTable } from './_helpers/filter'

export default {
  props: {
    emails: {
      type: Array,
      required: true,
    },
    filterParams: {
      type: Object,
      required: true,
    },
    height: {
      type: String,
      default: 'calc(100vh - 240px)',
    },
  },
  data() {
    return {
      headers: [
        { text: '件名', diplay: true, value: 'subject', sortable: true },
        { text: '差出人', diplay: true, value: 'from_email', sortable: true },
        { text: '宛先', diplay: true, value: 'to', sortable: true },
        { text: '日時', diplay: true, value: 'date_created', sortable: true },
        {
          text: 'ステータス',
          diplay: true,
          value: 'is_success',
          sortable: true,
        },
      ],
    }
  },
  computed: {
    footerProps() {
      return {
        'items-per-page-text': '表示件数',
        'items-per-page-options': [20, 50, 100],
      }
    },
    filteredEmails() {
      return filterEmailTable(this.emails, this.filterParams)
    },
  },
  methods: {},
}
</script>
