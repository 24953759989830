<template>
  <v-dialog
    v-model="dialog"
    max-width="1000px"
    max-height="3000px"
    class="d-block"
  >
    <v-card class="sweeep-dialog">
      <v-card-title v-text="$t('message.titleEmailDetail')" />
      <v-card-text class="pb-0 mb-0">
        <v-row no-gutters>
          <v-col cols="12" sm="6" class="pr-2 pl-2">
            <v-text-field v-model="form.from_email" label="差出人" readonly />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="form.to"
              label="宛先"
              readonly
              class="pr-2 pl-2"
            />
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" sm="6" class="pl-2">
            <v-text-field
              v-model="form.cc"
              readonly
              label="CC（複数指定はカンマ区切り）"
            />
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" sm="6" class="pr-2 pl-2">
            <v-text-field v-model="form.subject" label="件名" readonly />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="pt-0 mt-0">
        <v-row no-gutters>
          <div class="pl-2 pb-1">添付ファイル</div>
          <v-card-text class="pt-0 pb-2">
            <template v-for="(attachment, i) in form.attachments">
              <v-icon
                :key="i"
                v-tooltip="attachment.file_name"
                link
                color="blue"
                class="pr-4"
                @click="onClickAttachment(form.id, attachment)"
                v-text="'feather-file-text'"
              />
            </template>
          </v-card-text>
        </v-row>
        <v-row no-gutters class="px-2 h-100">
          <v-card tile outlined class="w-100 h-100">
            <v-card-text class="h-100">
              <v-textarea
                v-model="form.body"
                dense
                filled
                hide-details
                class="h-100 more-dense my-1 fs-12 fw-bold"
                style="overflow: scroll"
                rows="11"
                readonly
              />
            </v-card-text>
          </v-card>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      form: null,
    }
  },
  computed: {},
  methods: {
    open(form) {
      this.form = form
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    onClickAttachment(emailId, attachment) {
      try {
        this.$store.dispatch('getEmailAttachment', {
          emailId: emailId,
          attachmentId: attachment.id,
        })
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
